body {
  /* background-image: url(/image/bg_image.png); */
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: #fff !important;
}

h2 {
  margin-top: 8px;
  color: #fff;
}

a {
  color: #fff;
  text-decoration: none;
  font-size: 24px;
}

.hit_sidebar a.active,
a.link-is-active {
  font-weight: bold;
  font-style: italic;
  background: #575a82 !important;
  color: #fff;
}
form {
  padding: 20px 20px;
  box-shadow: 0px 0px 20px 0px #e0e0e0;
  background: #fff;
  margin: 23px;
}

.menuhvr:hover {
  background-color: saddlebrown;
}

.app-container {
  margin: 5% auto;
  width: 70vw;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.055);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
}
.menubutton,
.menubugger {
  cursor: pointer;
}
.Aside_left {
  width: 15%;
  float: left;
  height: 400px;
  background-color: rgba(255, 255, 255, 0.15);
  margin: 25px;
  padding: 15px;
  margin-top: 4%;
  border-right: 3px #fff;
}
.plans-container .plan-content {
  padding-bottom: 15px;
  margin-bottom: 10px;
  /* background: #ddd; */
  background: #ffffff;
  border-bottom: 1px solid #bfbfbf;
  box-shadow: 0px 0px 20px 0px #bbb;
}
.plans-container h2 {
  color: #313131;
  padding: 20px 15px 0px;
  font-size: 21px;
  font-weight: 600;
  margin: 0px;
}
.plan-content .plan-para {
  margin: 0px;
  padding: 4px 15px;
  font-size: 14px;
}
.Aside_right {
  width: calc(100% - 247px);
  float: right;
}
.Aside_rightfull {
  width: calc(100% - 65px);
}
.headleft {
  font-size: 25px;
  text-align: center;
}

.plans-container {
  grid-template-rows: 0.15fr 2fr;
  background: #ddd;
  /* box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, .75); */
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  width: 100%;
  padding: 0px;
  margin: 64px auto 0px;
  height: calc(100vh - 64px);
  overflow-x: hidden;
}

.prod {
  margin-bottom: 10px;
}

.app-header {
  display: grid;
}

.app-header a {
  width: 100%;
  color: #fff;
  font-size: 18px;
  display: flex;
  padding: 4px 19px;
}
.app-header a:hover {
  color: #ffa500;
  text-decoration: none;
}
.task-details-form {
  display: grid;
  grid-gap: 8px;
}

textarea {
  height: 100px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  margin: 0;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  outline: none;
  border: none;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  box-sizing: border-box;
  color: #fff;
}

.task-group-form {
  display: grid;
  grid-gap: 8px;
}

form.goal-form {
  display: grid;
}

select {
  height: 35px;
  border: 1px solid #7b7b7b;
  background: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  width: 100%;
}

.plan-form {
  display: grid;
  grid-gap: 8px;
}

.form-wrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.sign-up-form {
  width: 300px;
  display: grid;
  grid-gap: 8px;
}

label {
  color: #000;
  margin-top: 5px;
  margin-bottom: 0px !important;
}

input {
  width: 100%;
  margin: 0;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  outline: none;
  border: 1px solid #7b7b7b;
  background: #fff;
  border-radius: 7px;
  box-sizing: border-box;
  color: #383737;
  height: 40px;
}

select {
  margin: 0;
  font-size: 1rem;
  outline: none;
  background: #fff;
  border-radius: 7px;
  box-sizing: border-box;
  color: #000;
  height: 40px;
  padding: 0px 10px;
}

button {
  height: 45px;
  padding: 4px;
  font-size: 22px;
  font-weight: bold;
  background-color: #30c0ff;
  color: #fff;
  border-radius: 2px;
  border: none;
}

button:hover {
  cursor: pointer;
  background-color: #6b72a7;
}

.blur {
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

#modal-root {
  position: relative;
  z-index: 999;
}

.modal {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-cont {
  border-radius: 2px;
  padding: 16px;
  background: #fff;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.5);
  position: relative;
}

.advisor-invite {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 16px;
}

.advisor-invite-close {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: -16px;
  right: -16px;
  cursor: pointer;
}

.advisor-invite-close:before {
  content: '\D7';
  position: absolute;
  color: rgba(255, 255, 255, 0.75);
  font-size: 44px;
  top: -12px;
  left: 4px;
}

.advisor-invite-button-group {
  margin-top: 8px;
}

.advisor-content {
  float: right;
  width: calc(75% - 40px);
  padding: 20px;
}

.advisee-info-links {
  display: grid;
  grid-auto-flow: column;
  padding: 4px;
  color: #30c0ff;
}

.advisee-info-plans-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 49%);
  grid-gap: 10px;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-top: 8px;
}

.advisee-info-plan {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 8px 0px;
  color: #fff;
  border-bottom: 1px solid #fff;
}

.advisee-info-plan > span {
  padding: 8px;
}

.plan-card {
  display: grid;
  grid-gap: 4px;
  padding: 8px;
  margin-bottom: 8px;
  color: #fff;
}

.advisee-info-tasks-container {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 8px;
  max-height: 600px;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  margin-top: 8px;
  border-radius: 2px;
  color: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
}

.advisee-info-task-card {
  padding: 4px;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr 1fr;
}

.advisee-info-task-details {
  padding: 4px;
  display: grid;
  grid-gap: 4px;
}

.advisee-info-task-completion {
  display: grid;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  font-style: italic;
}
.menuimage {
  width: 22px;
  height: 22px;
  margin-top: 3px;
}
.menuimage1 {
  width: 28px;
}
.menubutton {
  float: left;
  margin-top: 8px !important;
  margin-right: 50px;
}
/* aside.hit_sidebar.open_sidbar.sidebar-slide-push ul li a{
  color: #3a3a64;
} */
aside.hit_sidebar.open_sidbar.sidebar-slide-push ul li img {
  width: 28px;
}
.menubugger {
  float: left;
  padding: 0 !important;
  min-width: 0;
  margin: 8px 25px 0px 0px !important;
}
.nopadd {
  padding: 0 !important;
}
.notification {
  width: 35px;
  padding-top: 0px;
}
@media screen and (min-width: 768px) {
  .app-header {
    justify-content: flex-start;
    width: 100%;
    display: block;
  }
  input {
    padding: 0.75rem 1rem;
  }
}

.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -6px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
  background: transparent;
  color: #000;
  border: none;
}
.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: none;
  background: none;
  box-shadow: none;
}
.files {
  position: relative;
}
.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: '';
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAQcNBBZ51gE7AAADzklEQVRo3u2YWWhTQRSGv9RiFdti64YU69KqVRCVuqBVcAnWpfggomLVolIURBBERZQg7rg8CQoiPojriw8WkUpdHhQUF1ARtQriUlFpbU3r1pjGh0xObm7u7V2SUJCcechk7vnP/8/cWc5cSFsXm8cVqoRyyhhOIdlk8p0vvOAxt7hHMNWC89nOW0Im5ROHGZw68hyO0mZKHintnGJAKugX8sGSPFKaWZ1ccg8Hdb28xla8FJNHHkXMZSc1/InxOUNWsuizuBTznreQb+jXj82813jeIjc5vb8oIQMcILtT717s57f436ZH4gKig/+VabYQpTQI5nyi9BWaoR9tG1XAE8GtS4Q+h3cqTAPFjpADZTb4KXAv4IjM+qmOsWP5odBn3dLn0apC7HOF36HQfxnpTsA2Gf6ervBZ1KsIx90JeKPgm9zBgTUqQhPdnYOLFfgXea4FZONXUeaYuWSYgsvVbx3NrgW0cVnVZjqBTWEnNTQn/AIAqlWUVq7iY7KVewbLeaA72Rxpj7NxumgPqTRPggq5aXC6u58BAJk0xsWsY5iR6ywZdO25vj4heoAKgzyimTK9W5km1wlyhbWMpm/C5BHLZRRLucAv4WhjttZhKC3y6Dpjkkast0LOCU8jgyLNHuqkeX8nCzM5tp52xXUnwrVK6A+lmDxs1cK3ONzwWFIop733Us/7SBgHdloxPsUDk9SfDkodhilXkypIlUNkf9miJ8A+Vb3pkt6dhBMK6YOrqrrRNb0bCXMV7gZ8VFUnOY+e3rmEPgpVj+wA9u90RvROJXhUjDZkrx5iEzpP8v4OgpJ0RX5X2pbwkxAhWqKvwPKo1PW+gw2SM1bJ5mJ3FPKV/0uoVdUNjukRATkscijBG117e1S11hJUoqEPn5JRAWgk/LUxoY8p310wXZSPtQBt1NHHCtBK2GsRqR/flWcpwDM5BzvfiscRiKHXC4hI6Ig9ag3suMK9CmdHa2Uh7bYATsQXc0HVC4Dx+CyTuErhWxJuyOCODK7P0WereAHWtlKW8f0oV4mEClHD8JQJGMBJ4WmiSPtotuazQjuXWMZQG7cZuwIyKaCCk5puBvDqnSpkZkbLN4PymvmmAqby3BATjIvrNdJZzCPDPV5fvsi70wt4agv/kBHmQ1UlN1rz8k2Wq17AS0vsWyqt8q5uzOAg92kgYEK/1PQVzOGzISbAB+5yiCnx5J0tOg+9DVpbCGkEhL+Z5dIqbUY3KX+qviG72Qd0lupbQFpAWkBaQFrA/y7gMwB+fnSV/AU04mdFV9EDeOjWlfT/g/0DqmcFwy/bQlAAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDEtMDdUMTM6MDQ6MjIrMDA6MDDaEyVJAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAxLTA3VDEzOjA0OjIyKzAwOjAwq06d9QAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.color input {
  background-color: #f1f1f1;
}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: ' or drag it here. ';
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  display: none;
}
.notranslate.public-DraftEditor-content {
  outline: none;
  background: #fff;
  border-radius: 0px;
  box-sizing: border-box;
  color: #000;
  height: 130px;
  padding: 1px 10px;
  border: 1px solid #7b7b7b;
}
.rdw-option-wrapper {
  background: transparent !important;
  border: none !important;
}
.rdw-dropdown-wrapper {
  background: transparent !important;
  border: none !important;
}
.rdw-dropdown-selectedtext {
  font-size: 12px !important;
}
.rdw-editor-toolbar {
  border-radius: 0 !important;
  border: 1px solid #7b7b7b !important;
  background: #dddddd !important;
  margin-bottom: -1px !important;
}
button.btn.savebutton {
  color: #fff;
  margin: 0px;
  border-radius: 7px !important;
  width: 100%;
}
button.btn.cancelbtn {
  border: 1px solid #6871f3;
  color: #6871f3;
  border-radius: 7px !important;
  width: 100%;
}
button.btn.cancelbtn:hover {
  color: #fff;
  background: #6871f3 !important;
  background: #6871f3 !important;
}
.react-datepicker-wrapper {
  width: 100%;
}

.btn-link,
.btn-link:focus {
  font-size: 14px;
  color: #007bff;
}
button.btn.removefile {
  background: #7379cc !important;
  color: #fff;
  margin: 10px 5px;
}
.choosepdf {
  height: 130px;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  overflow: hidden;
  word-break: break-word;
  padding: 6px;
}
.choosepdf i {
  padding-right: 3px;
}
.chooseimage {
  /* height: 120px;
  width: 120px; */
  height: 140px;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #ddd;
}
.imageicon {
  position: absolute;
  top: -7px;
  background: #000;
  color: #fff;
  height: 20px;
  width: 20px;
  text-align: center;
  padding: 3px;
  border-radius: 50px;
  font-size: 12px;
  left: auto;
  right: 10%;
  cursor: pointer;
}
/*storyboard*/
.taskgroup {
  background: #fff;
  padding: 0px;
  border: 1px solid #ddd;
  border-radius: 13px;
  margin: 0px 0px 0px 12px;
}
.taskgroup .taskicon1 {
  display: inline-block;
  border: 1px solid #8c8989;
  color: #8c8989;
  font-weight: 400;
  padding: 3px;
  border-radius: 50px;
  height: 22px;
  width: 22px;
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}
.taskgroup hr {
  margin: 10px 0px 0px 0px;
}
.activenumtext {
  font-size: 60px;
  margin: 0px;
  line-height: 60px;
}
.borradius13px {
  border-radius: 13px !important;
}
.backgroudtransparent {
  background: transparent !important;
}
.taskgroup ul {
  padding: 0px;
  list-style: none;
  margin: 0px;
}
.taskcontent {
  padding: 13px 13px 6px 13px;
}
.taskgroup ul li.active {
  cursor: pointer !important;
  background: #cee5ff;
}
.taskgroup ul li {
  cursor: pointer !important;
  padding: 10px;
  margin-bottom: 0px;
  font-size: 14px;
}
span.bg-pink {
  background: #dc3545;
  height: 20px;
  width: 20px;
  border: 1px solid #dc3545;
  display: inline-block;
  border-radius: 50px;
  vertical-align: middle;
  margin-right: 12px;
}
span.bg-blue {
  background: #513df1;
  height: 20px;
  width: 20px;
  border: 1px solid #513df1;
  display: inline-block;
  border-radius: 50px;
  vertical-align: middle;
  margin-right: 12px;
}
span.bg-skyblue {
  background: #007bff;
  height: 20px;
  width: 20px;
  border: 1px solid #007bff;
  display: inline-block;
  border-radius: 50px;
  vertical-align: middle;
  margin-right: 12px;
}
span.bg-green {
  background: #16d241;
  height: 20px;
  width: 20px;
  border: 1px solid #16d241;
  display: inline-block;
  border-radius: 50px;
  vertical-align: middle;
  margin-right: 12px;
}
span.bg-yellow {
  background: #ffc107;
  height: 20px;
  width: 20px;
  border: 1px solid #ffc107;
  display: inline-block;
  border-radius: 50px;
  vertical-align: middle;
  margin-right: 12px;
}
span.bg-orange {
  background: #ff4007;
  height: 20px;
  width: 20px;
  border: 1px solid #ff4007;
  display: inline-block;
  border-radius: 50px;
  vertical-align: middle;
  margin-right: 12px;
}
span.bg-default {
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 50px;
  vertical-align: middle;
  margin-right: 12px;
}

.table thead.thead-light1 {
  background: #fff;
}
.table thead.thead-light1 th:first-child {
  border-radius: 10px 0px 0px 10px;
}
.table thead.thead-light1 th:last-child {
  border-radius: 0px 10px 10px 0px;
}
.tablegoal tr:first-child td {
  border-top: 0px !important;
}
.tablegoal tr td {
  font-size: 13px;
}
.tablegoal a {
  font-size: 13px;
  color: #000000 !important;
  font-weight: 500;
}
.tasktable th {
  padding: 10px 5px;
}
.tablegoal .green-icon i,
.tablegoal .red-icon i {
  margin-top: 0px;
}
.inputpaddleft10px {
  padding-left: 10px !important;
}
.table thead.thead-light1 th {
  font-weight: 500;
  border: none;
  background: #ddd;
  font-size: 14px;
  width: calc(100% - 322px / 7);
}
.taskcontent .table .green-icon i {
  border: 1px solid #1dc51d;
  color: #1dc51d;
  border-radius: 5px;
  font-weight: 400;
  padding: 3px;
  height: 22px;
  width: 22px;
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}
.taskcontent .table .red-icon i {
  border: 1px solid red;
  color: red;
  border-radius: 5px;
  font-weight: 400;
  padding: 3px;
  height: 22px;
  width: 22px;
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  margin-right: 6px;
}
.table thead.thead-light1 th.pl-13 {
  padding-left: 13px !important;
}
.available-items {
  max-height: 300px !important;
  overflow-x: hidden;
  overflow-y: auto !important;
}
.available-items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(202, 92, 92, 0.3) !important;
  background-color: #f5f5f5 !important;
  border-radius: 10px !important;
}
.available-items::-webkit-scrollbar {
  height: 5px;
  width: 4px !important;
  background-color: #f5f5f5 !important;
}
.available-items::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(169, 169, 169)),
    color-stop(0.72, rgb(134, 132, 132)),
    color-stop(0.86, rgb(140, 140, 140))
  ) !important;
}
.employee-img {
  height: 30px;
  width: 30px;
  border: 1px solid #cecdcd;
  border-radius: 50px;
  margin-right: 5px;
}
.form-control.inputform {
  display: inline-block;
  width: 86%;
  margin: 0px 11px 0px 0px;
  height: 30px;
  font-size: 15px;
}
/* .table thead.thead-light1 th.tasktitle1 {
  padding-left: 10px !important;
  width: 2%;
} */
.filter-icon1 {
  color: #757272;
  padding-left: 5px;
}
.tasktable td.tablepadding-15 {
  padding-left: 15px !important;
}
.tasktable th {
  vertical-align: top !important;
}
.table thead.thead-light1 th {
  padding: 15px 5px !important;
}
.tasktable td {
  /* padding: 10px 6px !important; */
  padding: 10px 5px 10px 5px !important;
  text-align: left;
  font-size: 14px;
}
.tasktable th {
  width: 9%;
  font-size: 15px;
}
.tablename {
  display: inline-block;
  padding-top: 5px;
}
/* The radio */
.radio {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 5px;
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 99;
}

/* Create a custom radio button */
.checkround {
  position: absolute;
  top: 6px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-color: #ccc;
  border-style: solid;
  border-width: 2px;
  border-radius: 7px;
}

/* When the radio button is checked, add a blue background */
.radio input:checked ~ .checkround {
  background-color: #483ede;
  border-color: #483ede;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkround:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked ~ .checkround:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkround:after {
  left: 4px;
  top: 4px;
  width: 8px;
  height: 8px;
  background: #fff;
}
.available12 {
  max-height: 250px !important;
}
.table thead.thead-light1 th.goalname {
  padding-left: 20px !important;
}
@media screen and (max-width: 767px) {
  .col-md-3 .taskgroup {
    width: calc(100% - 25px);
  }
  .tableboxgroup .taskgroup {
    margin-left: 27px !important;
    margin-top: 15px;
    width: calc(100% - 38px);
  }
  .taskform select,
  .taskform input {
    margin-bottom: 15px;
  }
  .files input {
    padding: 120px 0px 85px 20%;
  }
  .cancelbtn {
    margin-left: 10px;
  }
  .tasktable td {
    padding: 10px 8px !important;
  }
}
@media screen and (max-width: 500px) {
  .inputfloat {
    margin: 13px;
    float: left;
  }
}
.isTaskActive {
  cursor: pointer !important;
  background: #cee5ff !important;
}
.table thead.thead-light1 th {
  cursor: pointer !important;
  padding: 12px 5px !important;
}
.tableTask {
  cursor: pointer !important;
}
.link-is-active {
  cursor: pointer !important;
}
.taskgroup select {
  height: 32px;
}
.nav-link.dropdown-toggle:hover {
  color: #fff;
}
.modalForm form {
  padding: 0px;
  box-shadow: none;
  margin: 0px;
}
.modalForm .avatar-upload {
  position: relative;
  max-width: 200px;
  margin: 20px auto;
}
.modalForm .avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 2px;
}
.modalForm .avatar-upload .avatar-edit input {
  display: none;
}
.modalForm .avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.modalForm .avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.modalForm .avatar-upload .avatar-edit input + label:after {
  content: '\F040';
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.modalForm .avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  /* border: 6px solid #f8f8f8; */
  /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); */
}
.modalForm .avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.modalForm .close {
  /* padding: 0px;
  margin: -6px -4px; */
  padding: 0px 2px 0px 0px !important;
  margin: -10px -4px !important;
  text-align: right;
  opacity: 1;
  cursor: pointer !important;
}

.dropDownSelect {
  margin: 0 !important;
  font-size: 1rem !important;
  outline: none !important;
  background-color: #fff;
  border-color: #000;
  font-size: inherit !important;
  height: 32px !important;
  background: #fff !important;
  border-radius: 7px !important;
  box-sizing: border-box !important;
  color: #000 !important;
  height: 32px !important;
  padding: 0px 10px !important;
  width: 100% !important;
  text-align: inherit !important;
  line-height: 0 !important;
}
.dropDownSelect.btn-primary {
  background-color: #fff;
  border-color: #000;
}

.dropDownSelect.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #003 !important;
  box-shadow: none !important;
}
.dropDownSelect.btn-primary.focus,
.btn-primary:focus {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #003 !important;
  box-shadow: none !important;
}
.dropdown-toggle::after {
  content: '\F107' !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: 0;
  border-left: none !important;
  color: #007bff;
  font-weight: bolder;
  font-size: 24px;
  font-family: fontawesome;
  vertical-align: baseline;
  vertical-align: initial;
  line-height: 0px;
}
.dropdown-menu .show {
  position: absolute !important;
  will-change: transform !important;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  -webkit-transform: translate3d(0px, -74px, 0px) !important;
          transform: translate3d(0px, -74px, 0px) !important;
}
.dropDownSelect.dropdown-item.active,
.dropDownSelect.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ccc;
}
.dropDownSelect.dropdown-item.focus,
.dropdown-item:focus {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #003 !important;
  box-shadow: none !important;
}
.dropDownSelect.dropdown-toggle::after {
  content: '\F107' !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: 0;
  border-left: none !important;
  color: #000 !important;
  font-weight: bolder;
  font-size: medium !important;
  font-family: fontawesome;
  vertical-align: middle !important;
  line-height: 0px;
}
.form-check-input {
  margin: -7px -19px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.close.button:hover {
  cursor: pointer !important;
  background-color: none !important;
}
.MuiAvatar-root {
  width: 100% !important;
  height: 100% !important;
}
.MuiAvatar-fallback {
  width: 0px !important;
  height: 49px !important;
}
.MuiListItemText-multiline {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 25px !important;
}
.MuiMenu-list {
  outline: 0;
  height: 300px !important;
}
.MuiAvatar-img {
  color: transparent;
  width: 56px !important;
  height: 58px !important;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
  background-color: rgba(0, 0, 0, 0.08);
}
.Profile-Img img {
  height: 100% !important;
  width: 100% !important;
}
.MuiListItem-root {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.MuiListItem-gutters {
  padding-left: 8px;
  padding-right: 10px;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
  background: none !important;
}
@media screen and (max-width: 767px) {
  .formContainer {
    position: static !important;
    position: initial !important;
  }
  .menuimage {
    /* left: 65px !important; */
    left: 9px !important;
    top: 3px !important;
    position: absolute !important;
    z-index: 1 !important;
    width: 30px;
    height: 27px;
  }
  .menuImage {
    height: 33px !important;
    width: 137px !important;
    position: absolute !important;
    bottom: 4px !important;
    left: 3px !important;
  }
  .menuimage1 {
    height: 32px !important;
    width: 31px !important;
    position: absolute !important;
    bottom: 5px !important;
    left: 3px !important;
  }
  .menuImageShow {
    height: 40px !important;
    width: 40px !important;
    position: absolute !important;
    bottom: 3px !important;
    left: 66px !important;
  }
  .admin-navbar .dropdown.text-right span {
    width: 50px;
    display: inline-block;
    float: left;
    /* margin: -4px -13px; */
    margin: -6px 3px;
  }
  .admin-navbar .dropnav1 {
    margin-left: 0px !important;
  }
  .dropnav1 li .mobres {
    display: none !important;
  }
  .taskgroup {
    margin: 0px 13px 0px 12px;
  }
  .table .dropDownSelect .dropdown-menu.show {
    -webkit-transform: translate3d(91px, -81px, 0px) !important;
            transform: translate3d(91px, -81px, 0px) !important;
  }
  .EXKLM {
    position: absolute !important;
    width: 0% !important;
    right: 325px;
    bottom: 9px;
  }
}
.card-title {
  margin-bottom: 0 !important;
}
.editorClassName rdw-editor-main {
  border: 1px solid #7b7b7b !important;
  position: static !important;
}
.rdw-editor-main {
  border: 1px solid #7b7b7b !important;
}
.notranslate .public-DraftEditor-content {
  border: none !important;
}
.DraftEditor-editorContainer div {
  border: none !important;
}
#taskGroupColor {
  color: #383737 !important;
}
.admin-navbar .dropdown span button {
  height: 40px;
  width: 40px !important;
  padding: 4px 0px;
  min-width: 50px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}
.available12-items {
  max-height: 500px !important;
}
::-webkit-input-placeholder {
  color: #383737;
  opacity: 1;
}
::-moz-placeholder {
  color: #383737;
  opacity: 1;
}
::-ms-input-placeholder {
  color: #383737;
  opacity: 1;
}
::placeholder {
  color: #383737;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #383737;
}

::-ms-input-placeholder {
  color: #383737;
}

.showMoreTextCls {
  color: #000 !important;
  font-size: 16px !important;
}

.taskgroup select {
  height: 32px;
  width: 176px !important;
  font-size: 13px !important;
}
/* .dropDownSelect .dropdown-toggle .btn .btn-primary {
  font-size: 14px !important;
} */

.dropdown-menu {
  /* transform: translate3d(5px, 35px, 0px) !important; */
  max-height: 100px !important;
  overflow-x: hidden;
  overflow-y: auto !important;
}
.dropdown-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(202, 92, 92, 0.3) !important;
  background-color: #f5f5f5 !important;
  border-radius: 10px !important;
}
.dropdown-menu::-webkit-scrollbar {
  height: 5px;
  width: 4px !important;
  background-color: #f5f5f5 !important;
}
.dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(169, 169, 169)),
    color-stop(0.72, rgb(134, 132, 132)),
    color-stop(0.86, rgb(140, 140, 140))
  ) !important;
}
/* .sc-ifAKCX dAzeyo .ld-emoji .emojione {
  height: 20px !important;
  width: 18px !important;
} */
.HJDRz {
  position: absolute !important;
  left: 64% !important;
}
.dropDownSelect .dropdown-menu.show {
  width: 100%;
  -webkit-transform: translate3d(0px, -80px, 0px) !important;
          transform: translate3d(0px, -80px, 0px) !important;
  position: absolute !important;
  top: 109px !important;
}
.companyModal .modal-title {
  font-size: 15px;
}
.companyModal i.fa-trash {
  position: absolute;
  right: 17%;
  top: 11px;
  font-size: 20px;
  cursor: pointer;
  z-index: 999;
}
.modal-header .close {
  font-size: 25px;
}
.termsCondition:focus,
.termsCondition:active:focus,
.termsCondition:focus {
  font-size: '12px';
  line-height: '23px';
  position: 'absolute';
  left: '86px';
  color: #007bff !important;
}
.inputTag.form-control {
  font-size: 12px;
  border-radius: 0;
  border: none;
  margin: 0px 0px -3px 0px;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-bottom: 1px solid #000 !important;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  outline: none !important;
}
.login-checkbox label {
  color: #222 !important;
}
.linkmodal .rdw-link-modal {
  left: -149%;
  width: 230px;
  min-height: 240px;
  padding: 10px 10px;
}
.linkmodal .rdw-image-modal,
.linkmodal .rdw-link-modal,
.linkmodal .rdw-embedded-modal {
  border: 1px solid #dad5d5;
}
.linkmodal .rdw-link-modal-input,
.linkmodal .rdw-embedded-modal-header-label,
.linkmodal .rdw-embedded-modal-link-input,
.linkmodal .rdw-embedded-modal-size-input {
  border: 1px solid #c5c5c5;
  margin-bottom: 10px;
  padding: 0 5px;
}
.linkmodal .rdw-link-modal-target-option input {
  width: 17px;
  vertical-align: middle;
}
.linkmodal .rdw-link-modal-btn,
.linkmodal .rdw-embedded-modal-btn,
.linkmodal .rdw-image-modal-btn {
  margin-left: 5px;
  min-width: 90px;
  height: 30px;
  border: 1px solid #9e9c9c;
  color: #000;
  display: inline-block;
  margin-top: 5px;
  padding: 2px;
  font-size: 14px;
}
.linkmodal .rdw-embedded-modal {
  right: -79%;
  left: auto;
  min-height: 220px;
}
.linkmodal .rdw-embedded-modal-header-option {
  width: 100%;
  justify-content: left;
  align-items: baseline;
}

.linkmodal .rdw-embedded-modal-size-input,
.linkmodal .rdw-image-modal-url-input,
.linkmodal .rdw-image-modal-size-input {
  border: 1px solid #c5c5c5;
  margin-bottom: 0px;
  padding: 0 11px;
}
table td h2 {
  color: #313131 !important;
  padding: 0px 1px 0px !important;
  font-size: 17px !important;
}
.img {
  height: auto !important;
  width: 100% !important;
}
.small-text {
  font-size: 16px;
  display: block;
  color: #000000 !important;
  font-weight: 500;
}
.selectTask select {
  width: 175px !important;
}
.table-head-fixed tbody {
  display: block;
  overflow: auto;
}
.table-head-fixed thead,
.table-head-fixed tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
#imagePreview .MuiAvatar-root {
  background: #c2c2c2;
  border-radius: 0px !important;
}
#imagePreview .MuiAvatar-circle {
  border-radius: 0px !important;
}
#imagePreview .MuiAvatar-root img {
  height: 100% !important;
  width: 100% !important;
  object-fit: contain !important;
  overflow: hidden;
}

#taskImages .MuiAvatar-root {
  border-radius: 0px !important;
  /* width: 120px !important; */
}

#taskImages .MuiAvatar-root img {
  height: 137px !important;
  width: 100% !important;
  object-fit: contain !important;
  overflow: hidden;
  margin-bottom: 15px;
  height: 130px !important;
  width: 100% !important;
  /* margin: 0px 15px 0px 0px; */
}
/* .imageicon {
  top: -7px;
  right: 15%;
} */
.word-body td {
  word-break: break-all;
}
.editorClassName a {
  color: #000;
  /* padding-left: 15px; */
}
.editorClassName a:hover {
  color: #000 !important;
  text-decoration: underline;
}
.rdw-link-decorator-icon {
  /* left: 0 !important; */
  left: auto !important;
}
.links-taskDetail {
  color: #000;
  font-size: 17px !important;
}
.links-taskDetail:hover,
.links-taskDetail:focus {
  color: #000;
  /* text-decoration: none; */
}
.table td.padding-body-right {
  padding-left: 11.3% !important;
  text-align: 'right';
  font-size: '14px';
}
.table td.action-body {
  text-align: center;
  padding-left: 12px !important;
  /* padding-right: 13px !important; */
}
.employeeDropdown {
  width: 176px !important;
  text-align: justify !important;
}
/* .modal-body a {
  text-decoration: none;
  font-size: 24px;
  word-break: break-word;
  color: #000 !important;
} */
.modal-body a {
  text-decoration: underline;
  font-size: 24px;
  word-break: break-word;
  color: #007bff !important;
}
.modal-body img {
  max-width: 100%;
}
.responsiveimagelogo {
  width: 40px;
  height: 40px;
}

.table-scroll-members {
  max-height: 400px !important;
}
.MuiBadge-root {
  color: #000000 !important;
}

/* Response */
@media screen and (max-width: 768px) {
  .table-resposnive-width.table thead.thead-light1 th {
    width: 130px !important;
  }
  .table-resposnive-width.table td select {
    width: 122px !important;
  }
  .table-resposnive-width.table td .radio {
    display: inline;
  }
  .tablename {
    display: inherit;
    word-break: break-all;
  }
  .table-resposnive-width1.table thead.thead-light1 th {
    width: 160px !important;
  }
  .table-resposnive-width1.table td.htmlContent {
    text-align: left !important;
    padding: 11px 33px !important;
  }
  .table-resposnive-width1.table td.selectTask {
    margin-left: -29%;
    display: inline-block;
  }
  .table-resposnive-width1.table td.padding-body-right {
    padding-left: 2.3% !important;
  }
  .table-resposnive-width1.table td.action-body {
    text-align: left;
  }
  .table thead.thead-light1 th {
    width: 140px;
  }
  .employeeDropdown {
    margin-left: -16%;
  }
  .table-responsive > .table-bordered th {
    width: 140px;
    font-size: 14px;
  }
  .responsiveimagelogo {
    margin-top: -11px;
  }
  .admin-navbar .dropdown.text-right span {
    margin: -5px 6px !important;
  }
  .dropDownSelect.btn-primary {
    font-size: 13px !important;
  }
}
/* Samsung S5 */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
  .admin-navbar .dropdown.text-right span {
    width: 29px;
    display: inline-block;
    margin: -6px 0px !important;
  }
  .dropDownSelect.btn-primary {
    font-size: 13px !important;
  }
}

/* I-Phone SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .admin-navbar .dropdown.text-right span {
    width: 31px;
    display: inline-block;
    margin: -6px 3px !important;
  }
  .menuimage {
    width: 24px;
    height: 28px;
  }
  .dropDownSelect.btn-primary {
    font-size: 13px !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .logohead {
    font-size: 15px;
    margin: 12px 34px 0px 0px !important;
  }
  .responsiveimagelogo {
    margin-top: -10px !important;
  }
}

@media screen and (max-width: 1500px) and (min-width: 1200px) {
  .logohead {
    font-size: 21px;
    margin: 3px 15px 0px 1px !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .logohead {
    font-size: 15px;
    margin: 10px 24px 0px 0px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .logohead {
    font-size: 15px;
    margin: 11px 32px 0px 0px !important;
  }
}

/* PORTRAIT: BlackBerry PlayBook */
@media only screen and (max-width: 600px) and (orientation: portrait) {
  .admin-navbar .dropdown.text-right span {
    margin: -2px -2px !important;
  }
}

/* PORTRAIT: Apple iPad */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .logohead {
    margin: 13px 25px 0px 0px !important;
  }
}

/* PORTRAIT: Jio Phone 2 */
@media all and (device-width: 240px) and (device-height: 320px) and (orientation: portrait) {
  .logohead {
    font-size: 12px;
    margin: 10px 0px 0px -2px;
  }
  .menuimage {
    width: 23px !important;
    height: 23px !important;
    margin-top: 3px;
    margin-left: -13px;
  }
  .responsiveimagelogo {
    width: 35px !important;
    height: 35px !important;
    margin-top: -10px;
  }
  .admin-navbar .dropdown.text-right span {
    margin: -15px -138px -26px -47px !important;
  }
  .menuImageShow {
    margin-left: -16px;
  }
}

.loginpage {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  display: flex;
  /* justify-content: space-evenly; */
}

#forgot-label {
  cursor: pointer;
  color: #000;
  font-size: 14px;
  text-decoration: underline;
}

.forgetPass {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  display: flex;
  /* justify-content: space-evenly; */
}
.dropDownSelect,
.dropDownSelect:hover,
.dropDownSelect.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  border: 1px solid #7b7b7b !important;
}
.dropDownSelect.dropdown-toggle::after {
  float: right !important;
}
.dropDownSelect .dropdown-menu.show {
  width: 100%;
}
.taskgroup .available-items {
  margin-bottom: 15px;
}
.dropDownSelect .dropdown-item {
  padding: 0px 10px;
  line-height: 30px;
}
.dropDownSelect .dropdown-item img {
  float: right;
}
.signUp {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  display: flex;
  /* justify-content: space-evenly; */
}
.modalForm .avatar-upload .avatar-preview,
.modalForm .avatar-upload .avatar-preview > div,
.modalForm .avatar-upload .avatar-preview > div .profile-Image {
  border-radius: 0px !important;
}
.signin-label {
  text-align: center;
}

.already-signin {
  color: #000;
  cursor: pointer;
  font-size: 14px;
}
span.signuppage {
  margin-left: 4px;
  text-decoration: underline;
  color: #000;
  cursor: pointer;
  font-size: 14px;
}
.appName {
  font-size: 14px;
  color: rgb(175, 173, 173);
  text-align: center;
}

.left-container {
  height: 100%;
  width: 50%;
  background-image: url(/static/media/bg_pic.20a20ad5.png);
}
.right-container {
  /* position: relative; */
  height: 100%;
  width: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  overflow-x: hidden;
  overflow-y: auto;
}

.formContainer {
  position: relative;
  min-height: 80%;
  min-width: 75%;
  /* border:2px solid blue; */
}
.forgotpass {
  text-align: right;
}
.form-style {
  position: absolute;
  left: 50%;
  top: 50%;
  /* bottom: 50%;
  right: 50%; */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* min-height: 60%; */
  min-width: 70%;
  /* width: 70%; */
  /* margin-top: 20px; */
  /* border:2px solid red; */
}

.loginhelp {
  width: 100%;
  margin-top: 15px;
  font-size: 12px;
  /* border: 2px solid red ; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 28px 0px;
}

.send-button {
  display: flex;
  justify-content: center;
  padding: 28px 0px;
}

button {
  padding: 5px;
  border: 1px solid rgb(66, 66, 89);
  min-width: 120px;
}

button:disabled {
  cursor: not-allowed;
  -webkit-filter: opacity(70%);
          filter: opacity(70%);
  background-color: rgb(66, 66, 89);
}

.button-left {
  color: white;
  border-radius: 3px;
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.button-left:hover {
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.button-right {
  color: white;
  border-radius: 3px;
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.button-right:hover {
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.button-send {
  color: white;
  border-radius: 3px;
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.button-send:hover {
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.letterName {
  font-weight: bold;
  letter-spacing: 3px;
  text-align: center;
  font-size: 24px;
}

.brandLastLetter {
  color: rgb(233, 233, 49);
}

.inputTag.form-control {
  font-size: 12px;
  border-radius: 0rem;
  border: none;
  border-bottom: 1px solid black;
}

.inputTag.form-control:focus {
  outline: none;
  box-shadow: none;
  border-bottom-color: black;
}
.checkboxdiv {
  margin-top: 6px;
}
.add_member select {
  padding: 8px;
  height: 50px;
}
.savebutton {
  background: #6871f3 !important;
  margin-top: 20px;
  border-color: #6871f3 !important;
  border-radius: 7px !important;
  width: 65%;
}
@media screen and (max-width: 992px) {
}

.dashboard {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.dashboardText {
  margin-top: 100px;
  font-size: 25px;
  font-weight: bold;
}

.cmpcontainer {
  border: 1px solid;
  width: 100%;
}

.align_left {
  margin-top: 1%;
  margin-left: 35%;
}

.drpcompany {
  padding: 8px;
  margin-left: 15px;
}

.drpcompany option {
  padding: 8px;
}

.cmpdetails {
  border: 1px solid;
  margin-top: 15px;
  margin-left: 30%;
  margin-right: 30%;
  padding: 15px;
}

.col-6 p {
  font-weight: bold;
}

.remeber_checkbox {
  width: 200px;
}
.remeber_checkbox input {
  float: left;
  width: inherit;
  margin-right: 4px;
}
.remeber_checkbox label {
  color: #222;
  margin-top: 2px;
}
.dropdownscroll .dropdown-menu.show {
  height: 350px;
  overflow: auto;
}

/*navbar css*/
.main-container {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}
#content_body {
  width: 100%;
  display: block;
  margin-left: 190px;
  -webkit-transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}
.margin_left {
  margin-left: 0px !important;
  -webkit-transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}

/*===================TOP NAVBAR FIXED NAVBAR===================*/
.nav-link {
  cursor: pointer;
}
.admin-navbar {
  /* background-color: #3a3a64; */
  background-color: #ffffff;
  border-color: rgb(66, 133, 244);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.navbar {
  position: relative;
  min-height: 64px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
.navbar-herader {
  display: inline-block;
}
.navbar button:focus {
  outline: none;
}
.navbar button {
  border: none;
  width: 50px;
  cursor: pointer;
  color: white;
  padding: 11px 15px;
  font-size: 21px;
  border-radius: 100%;
  background: none;
  margin-top: 0px;
  position: relative;
}
/* Ripple magic */
.navbar button {
  position: relative;
  overflow: hidden;
}
@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    -webkit-transform: scale(25, 25);
            transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(40, 40);
            transform: scale(40, 40);
  }
}
@keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    -webkit-transform: scale(25, 25);
            transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(40, 40);
            transform: scale(40, 40);
  }
}
.navbar button:focus:not(:active)::after {
  -webkit-animation: ripple 1s ease-out;
          animation: ripple 1s ease-out;
}
.admin-chat-logo img {
  width: 50%;
}

@media (min-width: 768px) {
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: -10px;
  }
}
/*******************TOP NAVBAR FIXED NAVBAR END*******************/

/*=======================SIDEBAR NAV START=======================*/
.hit_sidebar {
  position: relative;
  display: block;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  overflow: hidden;
  border: none;
  -webkit-transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  padding-top: 64px;
  background: #fff;
  width: 248px;
  /*    transform: translate3d(-280px,0,0);*/
}
.navbar-header.paddres {
  padding: 0px;
}
.small_sidebar {
  width: 65px;
  min-width: 64px;
  transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.sidebar-open {
  min-width: 274px;
  width: 274px;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.sidebar-slide-push {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9;
  border-right: 1px solid #ddd;
}
.profile-drop {
  cursor: pointer !important;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 50px;
}
.profile-drop-header {
  cursor: pointer !important;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 50px;
  margin-right: 5px !important;
}
.nav.navbar-nav {
  display: inline-flex;
}
.navbar-nav .dropdown-menu {
  position: absolute !important;
  right: 0;
  left: 26%;
  top: 49px;
  background: #ffffff;
  /* background: #9da1ca; */
  padding: 0;
}
.navbar-nav .dropdown-menu a {
  /* color: #fff; */
  color: #000000;
  font-size: 17px;
  text-align: left;
  padding: 7px 10px;
}
.nav-link.dropdown-toggle {
  display: inline;
  color: #fff;
  font-size: 17px;
  padding-left: 5px !important;
  vertical-align: middle;
}
.navbar-nav .dropdown-menu a:hover {
  /* background: #575a82; */
  background: #ffffff;
}
.dropdown-toggle::after {
  content: '\F107' !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: 0;
  border-left: none !important;
  /* color: #007bff; */
  color: #000000;
  font-weight: bolder;
  font-size: 24px;
  font-family: fontawesome;
  vertical-align: middle !important;
  line-height: 0px;
}
.edit-form label {
  color: #000;
}

/************************SIDEBAR NAV START END************************/
aside ul {
  margin: 0px;
  padding: 0px;
}
aside ul li {
  list-style-type: none;
  margin: 0;
  /* border-bottom: 1px solid #5991ce; */
}
aside ul li a {
  font-family: 'TitilliumWeb';
  display: block;
  text-decoration: none;
  text-align: left;
  color: #3a3a64;
  background-color: transparent;
  font-size: 15px;
  padding: 12px 0px 12px 6px;
}
aside ul li a:hover {
  background-color: #56598a;
  color: #fff;
  text-decoration: none;
}
.logoutlink a,
.logoutlink a.active {
  background: transparent !important;
}
.menubugger.in {
  display: block;
}
.menubutton.fade {
  display: none;
}
a:focus {
  outline: none;
  outline-offset: none;
}

aside a:hover,
a:focus {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
aside ul li a .nav-icon {
  margin-right: 15px;
  font-size: 20px !important;
}

.remove_text {
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  cursor: pointer !important;
}
.text_hide {
  display: none;
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}
aside ul li a span {
  display: table-cell;
  padding-left: 5px;
}
nav.navbar.navbar-fixed-top.admin-navbar {
  position: fixed;
  width: 100%;
  z-index: 99;
}
.searchbar {
  margin-bottom: auto;
  margin-top: auto;
  height: 43px;
  background-color: transparent;
  border-radius: 30px;
  padding: 5px;
  border: 1px solid #fff;
  margin-left: 30px;
}
.search_input {
  color: #fff;
  border: 0;
  outline: 0;
  background: none;
  width: 450px;
  caret-color: transparent;
  line-height: 0;
  -webkit-transition: width 0.4s linear;
  transition: width 0.4s linear;
  height: 32px;
  padding: 11px;
  caret-color: #fff;
}
.search_icon,
.search_icon:hover {
  height: 40px;
  width: 40px;
  float: right;
  display: flex;
  border-radius: 50%;
  color: gainsboro;
  text-decoration: none;
  padding: 2px 0px;
  justify-content: center;
  position: absolute;
  top: 6px;
  right: 20px;
}
.paddright0px {
  padding-right: 0px !important;
}
.search_input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #383737;
}
.menubugger {
  display: none;
}
.tablebgcolorset {
  background: rgba(0, 0, 0, 0.2);
}
.tablebgcolorset a,
.tablebgcolorset a:hover {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: green;
}

.tablebgcolorset th {
  text-align: center;
}
.tablebgcolorset td,
.tablebgcolorset th {
  vertical-align: top !important;
  color: #000;
}
.table {
  background: #fff;
}
.tablebgcolorset td {
  color: #fff !important;
}
.logohead {
  color: #fff;
  font-size: 25px;
  float: left;
  font-weight: bold;
  letter-spacing: 3px;
  margin: 4px 0px 0px 0px;
}
.table-responsive > .table-bordered th {
  border: 1px solid #b5b4b4;
  color: #00dcff;
}
.desknone {
  display: none !important;
}
.logoutlink a {
  color: #3a3a64 !important;
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .logohead {
    font-size: 21px;
  }
  .menubutton {
    margin-right: 0px;
    /* margin-left: 20px; */
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .navbar-header.paddres {
    padding: 0px;
  }
  .menubutton {
    margin-right: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .menubutton {
    margin-right: 33px;
  }
  .desknone {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .logohead {
    font-size: 15px;
    margin: 9px 0px 0px 0px !important;
  }
  .menubutton {
    margin-right: 33px;
  }
  .mobres {
    display: none;
  }
  .navbar-nav .dropdown-menu {
    left: -103px;
  }
}
@media screen and (max-width: 767px) {
  .logohead {
    color: #fff;
    font-size: 15px;
    margin-top: 0;
    vertical-align: top;
    padding-right: 0;
  }
  .prod {
    margin-top: 10px;
  }
  .desknone {
    display: block;
  }
  .mobres {
    display: none;
  }
  .menubugger.in {
    display: block;
    margin: -2px 5px 0px -4px !important;
  }
  .menuimage1 {
    width: 18px;
  }
  .dropnav1.ml-5 {
    margin-left: 0px !important;
  }
  .nav-link.dropdown-toggle {
    padding-left: 0px !important;
  }
  .menubutton {
    padding: 0px !important;
    margin: 0px -5px !important;
  }
  .paddres {
    padding: 0px !important;
  }
  .dropdown-menu {
    left: -94px !important;
  }
  .hit_sidebar {
    left: -300px;
  }
  /* .sidebar-slide-push.small_sidebar{
        display: block;
        width: 90%;
        z-index: 9;
        left:0px;
    } */
  .sidebar-slide-push.mobileshow {
    display: block;
    width: 90%;
    z-index: 9;
    left: 0px;
  }
  .sidebar-slide-push .text_hide {
    display: table-cell;
  }
  .plans-container {
    width: 100%;
    margin: 10% auto;
  }
  .Aside_right {
    width: 100%;
    margin: 30px 0px;
  }
  .plans-container h2 {
    font-size: 24px;
  }
  .notification {
    width: 31px;
  }
  .profile-drop {
    width: 34px;
    height: 34px;
  }
  .searchbar {
    height: 39px;
    padding: 6px;
  }
  .search_input {
    width: 100%;
    height: 26px;
    padding: 2px 8px;
    font-size: 13px;
  }
  .search_icon,
  .search_icon:hover {
    position: absolute;
    top: 5px;
    right: 20px;
  }
  .profile-Image {
    width: 100% !important;
    position: relative !important;
    height: 200px !important;
    margin-left: 0px !important;
    bottom: 1px !important;
    margin-left: 86px !important;
    right: 84px !important;
  }
  .profile-button {
    margin-left: 54px !important;
    margin-top: 11px !important;
    background: #6871f3 !important;
    border-color: #6871f3 !important;
  }
}
@media screen and (max-width: 580px) {
  .searchbar {
    border: none;
  }
  .search_icon,
  .search_icon:hover {
    position: absolute;
    right: 2%;
    top: 11%;
  }
  .search_input.in {
    display: block;
    position: absolute;
    background: #6b6bb7;
    bottom: -100%;
    left: -120px;
    z-index: 99999;
    width: 290px;
    opacity: 1;
  }
  input.search_input {
    font-size: 13px;
    padding: 6px;
    height: 41px;
    opacity: 0;
  }
  .Aside_right {
    margin: 0px;
  }
  .plans-container {
    margin: 15% auto;
  }
}
@media screen and (max-width: 360px) {
  .logohead {
    font-size: 12px;
  }
}

.profile-button {
  background: #6871f3 !important;
  border-color: #6871f3 !important;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#110B1A), to(#214858));
  background: linear-gradient(#110B1A, #214858);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

