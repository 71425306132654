.loginpage {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  display: flex;
  /* justify-content: space-evenly; */
}

#forgot-label {
  cursor: pointer;
  color: #000;
  font-size: 14px;
  text-decoration: underline;
}

.forgetPass {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  display: flex;
  /* justify-content: space-evenly; */
}
.dropDownSelect,
.dropDownSelect:hover,
.dropDownSelect.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  border: 1px solid #7b7b7b !important;
}
.dropDownSelect.dropdown-toggle::after {
  float: right !important;
}
.dropDownSelect .dropdown-menu.show {
  width: 100%;
}
.taskgroup .available-items {
  margin-bottom: 15px;
}
.dropDownSelect .dropdown-item {
  padding: 0px 10px;
  line-height: 30px;
}
.dropDownSelect .dropdown-item img {
  float: right;
}
.signUp {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  display: flex;
  /* justify-content: space-evenly; */
}
.modalForm .avatar-upload .avatar-preview,
.modalForm .avatar-upload .avatar-preview > div,
.modalForm .avatar-upload .avatar-preview > div .profile-Image {
  border-radius: 0px !important;
}
.signin-label {
  text-align: center;
}

.already-signin {
  color: #000;
  cursor: pointer;
  font-size: 14px;
}
span.signuppage {
  margin-left: 4px;
  text-decoration: underline;
  color: #000;
  cursor: pointer;
  font-size: 14px;
}
.appName {
  font-size: 14px;
  color: rgb(175, 173, 173);
  text-align: center;
}

.left-container {
  height: 100%;
  width: 50%;
  background-image: url('../images/bg_pic.png');
}
.right-container {
  /* position: relative; */
  height: 100%;
  width: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  overflow-x: hidden;
  overflow-y: auto;
}

.formContainer {
  position: relative;
  min-height: 80%;
  min-width: 75%;
  /* border:2px solid blue; */
}
.forgotpass {
  text-align: right;
}
.form-style {
  position: absolute;
  left: 50%;
  top: 50%;
  /* bottom: 50%;
  right: 50%; */
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* min-height: 60%; */
  min-width: 70%;
  /* width: 70%; */
  /* margin-top: 20px; */
  /* border:2px solid red; */
}

.loginhelp {
  width: 100%;
  margin-top: 15px;
  font-size: 12px;
  /* border: 2px solid red ; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 28px 0px;
}

.send-button {
  display: flex;
  justify-content: center;
  padding: 28px 0px;
}

button {
  padding: 5px;
  border: 1px solid rgb(66, 66, 89);
  min-width: 120px;
}

button:disabled {
  cursor: not-allowed;
  filter: opacity(70%);
  background-color: rgb(66, 66, 89);
}

.button-left {
  color: white;
  border-radius: 3px;
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.button-left:hover {
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.button-right {
  color: white;
  border-radius: 3px;
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.button-right:hover {
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.button-send {
  color: white;
  border-radius: 3px;
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.button-send:hover {
  background-color: rgb(64, 63, 121);
  min-width: 135px;
  border: none;
  font-size: 16px;
}
.letterName {
  font-weight: bold;
  letter-spacing: 3px;
  text-align: center;
  font-size: 24px;
}

.brandLastLetter {
  color: rgb(233, 233, 49);
}

.inputTag.form-control {
  font-size: 12px;
  border-radius: 0rem;
  border: none;
  border-bottom: 1px solid black;
}

.inputTag.form-control:focus {
  outline: none;
  box-shadow: none;
  border-bottom-color: black;
}
.checkboxdiv {
  margin-top: 6px;
}
.add_member select {
  padding: 8px;
  height: 50px;
}
.savebutton {
  background: #6871f3 !important;
  margin-top: 20px;
  border-color: #6871f3 !important;
  border-radius: 7px !important;
  width: 65%;
}
@media screen and (max-width: 992px) {
}

.dashboard {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.dashboardText {
  margin-top: 100px;
  font-size: 25px;
  font-weight: bold;
}

.cmpcontainer {
  border: 1px solid;
  width: 100%;
}

.align_left {
  margin-top: 1%;
  margin-left: 35%;
}

.drpcompany {
  padding: 8px;
  margin-left: 15px;
}

.drpcompany option {
  padding: 8px;
}

.cmpdetails {
  border: 1px solid;
  margin-top: 15px;
  margin-left: 30%;
  margin-right: 30%;
  padding: 15px;
}

.col-6 p {
  font-weight: bold;
}

.remeber_checkbox {
  width: 200px;
}
.remeber_checkbox input {
  float: left;
  width: inherit;
  margin-right: 4px;
}
.remeber_checkbox label {
  color: #222;
  margin-top: 2px;
}
.dropdownscroll .dropdown-menu.show {
  height: 350px;
  overflow: auto;
}

/*navbar css*/
.main-container {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}
#content_body {
  width: 100%;
  display: block;
  margin-left: 190px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}
.margin_left {
  margin-left: 0px !important;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}

/*===================TOP NAVBAR FIXED NAVBAR===================*/
.nav-link {
  cursor: pointer;
}
.admin-navbar {
  /* background-color: #3a3a64; */
  background-color: #ffffff;
  border-color: rgb(66, 133, 244);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.navbar {
  position: relative;
  min-height: 64px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
.navbar-herader {
  display: inline-block;
}
.navbar button:focus {
  outline: none;
}
.navbar button {
  border: none;
  width: 50px;
  cursor: pointer;
  color: white;
  padding: 11px 15px;
  font-size: 21px;
  border-radius: 100%;
  background: none;
  margin-top: 0px;
  position: relative;
}
/* Ripple magic */
.navbar button {
  position: relative;
  overflow: hidden;
}
@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}
.navbar button:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}
.admin-chat-logo img {
  width: 50%;
}

@media (min-width: 768px) {
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: -10px;
  }
}
/*******************TOP NAVBAR FIXED NAVBAR END*******************/

/*=======================SIDEBAR NAV START=======================*/
.hit_sidebar {
  position: relative;
  display: block;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  overflow: hidden;
  border: none;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  padding-top: 64px;
  background: #fff;
  width: 248px;
  /*    transform: translate3d(-280px,0,0);*/
}
.navbar-header.paddres {
  padding: 0px;
}
.small_sidebar {
  width: 65px;
  min-width: 64px;
  transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.sidebar-open {
  min-width: 274px;
  width: 274px;
  transform: translate3d(0, 0, 0);
}
.sidebar-slide-push {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9;
  border-right: 1px solid #ddd;
}
.profile-drop {
  cursor: pointer !important;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 50px;
}
.profile-drop-header {
  cursor: pointer !important;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 50px;
  margin-right: 5px !important;
}
.nav.navbar-nav {
  display: inline-flex;
}
.navbar-nav .dropdown-menu {
  position: absolute !important;
  right: 0;
  left: 26%;
  top: 49px;
  background: #ffffff;
  /* background: #9da1ca; */
  padding: 0;
}
.navbar-nav .dropdown-menu a {
  /* color: #fff; */
  color: #000000;
  font-size: 17px;
  text-align: left;
  padding: 7px 10px;
}
.nav-link.dropdown-toggle {
  display: inline;
  color: #fff;
  font-size: 17px;
  padding-left: 5px !important;
  vertical-align: middle;
}
.navbar-nav .dropdown-menu a:hover {
  /* background: #575a82; */
  background: #ffffff;
}
.dropdown-toggle::after {
  content: '\f107' !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: 0;
  border-left: none !important;
  /* color: #007bff; */
  color: #000000;
  font-weight: bolder;
  font-size: 24px;
  font-family: fontawesome;
  vertical-align: middle !important;
  line-height: 0px;
}
.edit-form label {
  color: #000;
}

/************************SIDEBAR NAV START END************************/
aside ul {
  margin: 0px;
  padding: 0px;
}
aside ul li {
  list-style-type: none;
  margin: 0;
  /* border-bottom: 1px solid #5991ce; */
}
aside ul li a {
  font-family: 'TitilliumWeb';
  display: block;
  text-decoration: none;
  text-align: left;
  color: #3a3a64;
  background-color: transparent;
  font-size: 15px;
  padding: 12px 0px 12px 6px;
}
aside ul li a:hover {
  background-color: #56598a;
  color: #fff;
  text-decoration: none;
}
.logoutlink a,
.logoutlink a.active {
  background: transparent !important;
}
.menubugger.in {
  display: block;
}
.menubutton.fade {
  display: none;
}
a:focus {
  outline: none;
  outline-offset: none;
}

aside a:hover,
a:focus {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
aside ul li a .nav-icon {
  margin-right: 15px;
  font-size: 20px !important;
}

.remove_text {
  transition-delay: 0s;
  cursor: pointer !important;
}
.text_hide {
  display: none;
  transition-delay: 2s;
}
aside ul li a span {
  display: table-cell;
  padding-left: 5px;
}
nav.navbar.navbar-fixed-top.admin-navbar {
  position: fixed;
  width: 100%;
  z-index: 99;
}
.searchbar {
  margin-bottom: auto;
  margin-top: auto;
  height: 43px;
  background-color: transparent;
  border-radius: 30px;
  padding: 5px;
  border: 1px solid #fff;
  margin-left: 30px;
}
.search_input {
  color: #fff;
  border: 0;
  outline: 0;
  background: none;
  width: 450px;
  caret-color: transparent;
  line-height: 0;
  -webkit-transition: width 0.4s linear;
  transition: width 0.4s linear;
  height: 32px;
  padding: 11px;
  caret-color: #fff;
}
.search_icon,
.search_icon:hover {
  height: 40px;
  width: 40px;
  float: right;
  display: flex;
  border-radius: 50%;
  color: gainsboro;
  text-decoration: none;
  padding: 2px 0px;
  justify-content: center;
  position: absolute;
  top: 6px;
  right: 20px;
}
.paddright0px {
  padding-right: 0px !important;
}
.search_input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #383737;
}
.menubugger {
  display: none;
}
.tablebgcolorset {
  background: rgba(0, 0, 0, 0.2);
}
.tablebgcolorset a,
.tablebgcolorset a:hover {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: green;
}

.tablebgcolorset th {
  text-align: center;
}
.tablebgcolorset td,
.tablebgcolorset th {
  vertical-align: top !important;
  color: #000;
}
.table {
  background: #fff;
}
.tablebgcolorset td {
  color: #fff !important;
}
.logohead {
  color: #fff;
  font-size: 25px;
  float: left;
  font-weight: bold;
  letter-spacing: 3px;
  margin: 4px 0px 0px 0px;
}
.table-responsive > .table-bordered th {
  border: 1px solid #b5b4b4;
  color: #00dcff;
}
.desknone {
  display: none !important;
}
.logoutlink a {
  color: #3a3a64 !important;
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .logohead {
    font-size: 21px;
  }
  .menubutton {
    margin-right: 0px;
    /* margin-left: 20px; */
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .navbar-header.paddres {
    padding: 0px;
  }
  .menubutton {
    margin-right: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .menubutton {
    margin-right: 33px;
  }
  .desknone {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .logohead {
    font-size: 15px;
    margin: 9px 0px 0px 0px !important;
  }
  .menubutton {
    margin-right: 33px;
  }
  .mobres {
    display: none;
  }
  .navbar-nav .dropdown-menu {
    left: -103px;
  }
}
@media screen and (max-width: 767px) {
  .logohead {
    color: #fff;
    font-size: 15px;
    margin-top: 0;
    vertical-align: top;
    padding-right: 0;
  }
  .prod {
    margin-top: 10px;
  }
  .desknone {
    display: block;
  }
  .mobres {
    display: none;
  }
  .menubugger.in {
    display: block;
    margin: -2px 5px 0px -4px !important;
  }
  .menuimage1 {
    width: 18px;
  }
  .dropnav1.ml-5 {
    margin-left: 0px !important;
  }
  .nav-link.dropdown-toggle {
    padding-left: 0px !important;
  }
  .menubutton {
    padding: 0px !important;
    margin: 0px -5px !important;
  }
  .paddres {
    padding: 0px !important;
  }
  .dropdown-menu {
    left: -94px !important;
  }
  .hit_sidebar {
    left: -300px;
  }
  /* .sidebar-slide-push.small_sidebar{
        display: block;
        width: 90%;
        z-index: 9;
        left:0px;
    } */
  .sidebar-slide-push.mobileshow {
    display: block;
    width: 90%;
    z-index: 9;
    left: 0px;
  }
  .sidebar-slide-push .text_hide {
    display: table-cell;
  }
  .plans-container {
    width: 100%;
    margin: 10% auto;
  }
  .Aside_right {
    width: 100%;
    margin: 30px 0px;
  }
  .plans-container h2 {
    font-size: 24px;
  }
  .notification {
    width: 31px;
  }
  .profile-drop {
    width: 34px;
    height: 34px;
  }
  .searchbar {
    height: 39px;
    padding: 6px;
  }
  .search_input {
    width: 100%;
    height: 26px;
    padding: 2px 8px;
    font-size: 13px;
  }
  .search_icon,
  .search_icon:hover {
    position: absolute;
    top: 5px;
    right: 20px;
  }
  .profile-Image {
    width: 100% !important;
    position: relative !important;
    height: 200px !important;
    margin-left: 0px !important;
    bottom: 1px !important;
    margin-left: 86px !important;
    right: 84px !important;
  }
  .profile-button {
    margin-left: 54px !important;
    margin-top: 11px !important;
    background: #6871f3 !important;
    border-color: #6871f3 !important;
  }
}
@media screen and (max-width: 580px) {
  .searchbar {
    border: none;
  }
  .search_icon,
  .search_icon:hover {
    position: absolute;
    right: 2%;
    top: 11%;
  }
  .search_input.in {
    display: block;
    position: absolute;
    background: #6b6bb7;
    bottom: -100%;
    left: -120px;
    z-index: 99999;
    width: 290px;
    opacity: 1;
  }
  input.search_input {
    font-size: 13px;
    padding: 6px;
    height: 41px;
    opacity: 0;
  }
  .Aside_right {
    margin: 0px;
  }
  .plans-container {
    margin: 15% auto;
  }
}
@media screen and (max-width: 360px) {
  .logohead {
    font-size: 12px;
  }
}

.profile-button {
  background: #6871f3 !important;
  border-color: #6871f3 !important;
}
